import { restService } from './RestService'

const REST_ENDPOINTS = {
  AUTH_LOGIN_ENDPOINT: 'api/auth/login',
  IDENTITY_TOKEN_ENDPOINT: 'api/auth/identityToken',
  DOSSIER_ENDPOINT: 'api/dossiers/${dossierId}/instances'
}
const MSTR_AUTH_TOKEN = 'X-MSTR-authToken'
const MSTR_IDENTITY_TOKEN = 'X-MSTR-IdentityToken'

class EmbeddingService {
  async renderDossier (
    placeholder,
    mstrLibraryUrl,
    projectId,
    dossierId,
    dossierInstance,
    mode = 'consumption'
  ) {
    try {
      const dossierUrl = `${mstrLibraryUrl}app/${projectId}/${dossierId}`

      const config = {
        placeholder,
        url: dossierUrl,
        disableNotification: true,
        enableResponsive: true,
        instance: dossierInstance,
        dossierRenderingMode: mode,
        filterFeature: {
          enabled: false,
          edit: false,
          summary: true
        },
        // TODO: This navigation bar is enabled temporary
        navigationBar: {
          enabled: true,
          title: true
        }
      }

      // we need to handle a situation when a user switches to another page while the dossier is loading
      if (!placeholder) {
        return
      }
      const dossier = await window.microstrategy.dossier.create(config)
      console.log(dossier)
    } catch (e) {
      throw new Error('EMBEDDING_DOSSIER_ERROR', 'warning', 'snackbar')
    }
  }

  async createDossierInstance (config) {
    const authToken = await this.createSessionAuthToken(config)
    // const identityToken = await this.createIdentityToken(config, authToken);
    const mid = await this.createInstance(
      config,
      null,
      authToken,
      config.dossierId,
      config.projectID
    )
    console.log('🚀 ~ EmbeddingService ~ createDossierInstance ~ mid:', mid)
    document.getElementById('errorTextApi').innerHTML = mid

    return mid
  }

  async createSessionAuthToken (config) {
    const pepe = 'pepecitoenString'
    // console.log('🚀 ~ EmbeddingService ~ createSessionAuthToken ~ config:', config);
    // console.log(config);
    // TODO Token de Fran.
    const url = `${config.baseURL}${REST_ENDPOINTS.AUTH_LOGIN_ENDPOINT}`
    let body = {
      loginMode: config.loginMode
    }
    if (config.loginMode === 1) {
      body = {
        ...body,
        username: config.username,
        password: config.password
        // ...config,
      }
    }
    const response = await restService.post(url, body, null, null, null, null)
    if (response.ok) {
      return response.headers.get(MSTR_AUTH_TOKEN)
    } else {
      const res = await response.json()
      throw new Error("Couldn't login the user : " + res)
    }
  }

  async createIdentityToken (config, authToken) {
    const url = `${config.baseURL}${REST_ENDPOINTS.IDENTITY_TOKEN_ENDPOINT}`
    const response = await restService.post(url, null, null, authToken, null, null)
    if (response.ok) {
      return response.headers.get(MSTR_IDENTITY_TOKEN)
    } else {
      const res = await response.json()
      throw new Error("Couldn't login the user : " + res)
    }
  }

  async createInstance (config, identityToken, authToken, dossierId, projectID) {
    console.log(
      '🚀🚀🚀🚀🚀 ~ EmbeddingService ~ createInstance ~ createInstance: config, identityToken, authToken, dossierId, projectID',
      config,
      identityToken,
      authToken,
      dossierId,
      projectID
    )
    let url = `${config.baseURL}${REST_ENDPOINTS.DOSSIER_ENDPOINT}`
    url = url.replace('${dossierId}', dossierId)
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }

    const response = await restService.post(
      url,
      null,
      identityToken,
      authToken,
      projectID,
      headers
    )
    console.log('🚀🚀🚀🚀🚀 ~ EmbeddingService ~ createInstance ~ response:', response)
    if (response.ok) {
      const res = await response.json()
      return res
    } else {
      const res = await response.json()

      throw new Error("Couldn't login the user : " + res, '-->', response)
    }
  }
}

export const embeddingService = new EmbeddingService()
