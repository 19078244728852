import { Box } from '@mui/material'

import { useEffect } from 'react'
import { embeddingService } from '../../services/poc/EmbeddingService'

function AnalyticsPage (props) {
  const config = {
    baseURL: process.env.REACT_APP_BASE_URL_CIVICA || 'https://mstr.civica-soft.com/mstrlib-oidc/',
    username: process.env.REACT_APP_CIVICA_USERNAME || 'mstr',
    password: process.env.REACT_APP_CIVICA_PASSWORD || 'qQy8S3Dk5cobQZhR',
    loginMode: 1,
    projectID: '85E51F7A3D41E6EF80E4C1AF0824EC8D',
    dossierId: 'BE76232A564C10F9E8844E9DC150C533'
  }

  useEffect(() => {
    embeddingService.createDossierInstance(config).then(instance => {
      if (instance != null) {
        embeddingService.renderDossier(
          document.getElementById('analytics-container'),
          config.baseURL,
          config.projectID,
          config.dossierId,
          instance
        )
      }
    })
  }, [])
  return (
    <Box
      id='analytics-container'
      sx={{ margin: '1%', height: '90% !important', width: '90%' }}
    />
  )
}
export default AnalyticsPage
