import { embeddingService } from './EmbeddingService'
class AuthenticationServiceOIDC {
  async getAuthToken (config) {
    const options = {
      method: 'GET',
      credentials: 'include', // Including cookie
      mode: 'cors', // Setting as cors mode for cross origin
      headers: { 'content-type': 'application/json' }
    }
    return fetch(config.baseURL + 'api/auth/token', options)
      .then(response => {
        if (response.ok) {
          return response.headers.get('x-mstr-authToken')
        } else {
          // Log the response json if it failed
          return null
        }
      })
      .catch(error => {
        // Log the error if failed
        return null
      })
  }

  async checkLogin (config, newTab, startTime, timeout, interval) {
    const currentTime = Date.now()
    const token = await this.getAuthToken(config).catch(error => {
      console.log(error)
      return null
    })
    console.log('🚀 ~ AuthenticationServiceOIDC ~ checkLogin ~ token:', token)
    // If auth token is valid return it
    if (token) {
      if (newTab && !newTab.closed) {
        newTab.close()
      }
      embeddingService
        .createInstance(config, null, token, config.dossierId, config.projectID)
        .then(instance => {
          embeddingService.renderDossier(
            document.getElementById('analytics-container'),
            config.baseURL,
            config.projectID,
            config.dossierId,
            instance
          )
        })
    } else if (currentTime - startTime > timeout) {
      console.log('OIDC login timed out, please try again')
      return null
    } else {
      // Check if the user logged in already every interval milliseconds
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(this.checkLogin(config, newTab, startTime, timeout, interval))
        }, interval)
      })
    }
  }

  async loginAndEmbedDossier (config) {
    const newTab = window.open(
      config.baseURL + 'auth/login-dialog.jsp?loginMode=' + config.loginMode,
      '_blank'
    )
    const startTime = Date.now()
    return this.checkLogin(config, newTab, startTime, 120000, 2000)
  }
}

export const authenticationServiceOIDC = new AuthenticationServiceOIDC()
