import { Box } from '@mui/material'
import { useEffect } from 'react'
import { authenticationServiceOIDC } from '../../services/poc/AuthenticationServiceOIDC'

function AnalyticsPageOIDC (props) {
  const config = {
    baseURL: process.env.REACT_APP_BASE_URL_CIVICA || 'https://mstr.civica-soft.com/mstrlib-oidc/',
    loginMode: 4194304,
    projectID: '85E51F7A3D41E6EF80E4C1AF0824EC8D',
    dossierId: 'BE76232A564C10F9E8844E9DC150C533'
  }

  useEffect(() => {
    authenticationServiceOIDC.loginAndEmbedDossier(config)
  }, [])

  return (
    <Box
      id='analytics-container'
      sx={{ margin: '1%', height: '90% !important', width: '90%' }}
    />
  )
}
export default AnalyticsPageOIDC
