class RestService {
  prepareRequestDetails = (identityToken, authToken, projectId, _headers) => {
    const headers = _headers || {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
    const requestDetails = {
      headers,
      credentials: 'include',
      mode: 'cors'
    }

    if (identityToken) {
      requestDetails.headers['X-MSTR-identityToken'] = identityToken
    }

    if (authToken) {
      requestDetails.headers['X-MSTR-authToken'] = authToken
    }

    if (projectId) {
      requestDetails.headers['X-MSTR-ProjectID'] = projectId
    }

    return requestDetails
  }

  sendRequest = async (url, options, ignoreUnauthorized) => {
    console.log('🚀 ~ RestService ~ sendRequest= ~ url, options ===>', url, options)
    try {
      const res = await fetch(url, options)
      console.log('sendRequest= ~ res:', res)
      if (!ignoreUnauthorized && res.status === 401) {
        console.log(
          'ignoreUnauthorized && res.status === 401',
          ignoreUnauthorized,
          res.status
        )
        throw new Error()
      }

      return res
    } catch (error) {
      console.error('error on sendRequest', error, '--->', url)
    }
  }

  get = async (url, identityToken, authToken, projectId, ignoreUnauthorized) => {
    const requestDetails = this.prepareRequestDetails(
      identityToken,
      authToken,
      projectId
    )

    const options = {
      method: 'GET',
      ...requestDetails
    }

    return this.sendRequest(url, options, ignoreUnauthorized)
  }

  post = async (url, body, identityToken, authToken, projectId, headers) => {
    const requestDetails = this.prepareRequestDetails(
      identityToken,
      authToken,
      projectId,
      headers
    )

    if (!(body instanceof URLSearchParams)) {
      body = JSON.stringify(body)
    } else {
      body = body.toString()
    }
    const options = {
      method: 'POST',
      body,
      ...requestDetails
    }

    return this.sendRequest(url, options)
  }
}

export const restService = new RestService()
