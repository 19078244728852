class FetchAPI {
  constructor (baseURL) {
    this.baseURL = baseURL
  }

  async fetchRequest (endpoint, method, data, headers) {
    const url = `${this.baseURL}/${endpoint}`
    console.log('🚀 ~ FetchAPI ~ fetchRequest ~ url:', url)
    const options = {
      method,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      ...headers
    }

    if (data) {
      options.body = JSON.stringify(data)
    }

    const response = await fetch(url, options)
    console.log('🚀🚀🚀🚀 ~ FetchAPI ~ fetchRequest ~ response:', response)
    return response
  }

  async get (endpoint) {
    const response = await this.fetchRequest(endpoint, 'GET')
    const data = await response.json()
    console.log('🚀datadatadata ~ FetchAPI ~ get ~ data:', data)

    if (!response.ok) {
      throw new Error(`Error fetching ${endpoint}: ${response.status}`)
    }

    return data
  }

  async post (endpoint, data) {
    console.log('🚀 ~ FetchAPI ~ post ~ endpoint, data:', endpoint, data)
    const response = await this.fetchRequest(endpoint, 'POST', data)
    const responseData = await response.json()

    if (!response.ok) {
      throw new Error(`Error posting to ${endpoint}: ${response.status}`)
    }

    return responseData
  }

  async put (endpoint, data) {
    const response = await this.fetchRequest(endpoint, 'PUT', data)
    const responseData = await response.json()

    if (!response.ok) {
      throw new Error(`Error putting to ${endpoint}: ${response.status}`)
    }

    return responseData
  }

  async delete (endpoint) {
    const response = await this.fetchRequest(endpoint, 'DELETE')

    if (!response.ok) {
      throw new Error(`Error deleting ${endpoint}: ${response.status}`)
    }

    return response
  }

  async getDashboard (dashboardId) {
    const url = `${dashboardId}/${process.env.REACT_APP_API_ENDPOINT}`
    return await this.get(url)
  }
}

// TODO: Complete this url with the correct endpoint ej: new FetchAPI('https://pokeapi.co/api/v2')
export const apiService = new FetchAPI(process.env.REACT_APP_API_BASE_URL)
