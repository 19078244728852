import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'

import './App.css'
import AnalyticsPage from './pages/poc/AnalyticsPage'
import AnalyticsPageOIDC from './pages/poc/AnalyticsPageOIDC'
import AnalyticsPageById from './pages/AnalyticsPageById'

function App () {
  return (
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <Router>
        <Routes>
          <Route path={`${process.env.PUBLIC_URL}`} element={<AnalyticsPage />} />
          <Route
            path={`${process.env.PUBLIC_URL}/oidc`}
            element={<AnalyticsPageOIDC />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/:paramsID`}
            element={<AnalyticsPageById />}
          />
        </Routes>
      </Router>
    </CookiesProvider>
  )
}

export default App
